import axios from "axios";

axios.defaults.headers.post["x-csrf-token"] =
    document.getElementById("csrf") && document.getElementById("csrf").value;
axios.defaults.headers.post["Content-Type"] = "application/json";

const END_POINT = process.env.REACT_APP_CORE_API;

function assertString(input) {
    const isString = typeof input === "string" || input instanceof String;

    if (!isString) {
        let invalidType;
        if (input === null) {
            invalidType = "null";
        } else {
            invalidType = typeof input;
            if (
                invalidType === "object" &&
                input.constructor &&
                input.constructor.hasOwnProperty("name")
            ) {
                invalidType = input.constructor.name;
            } else {
                invalidType = `a ${invalidType}`;
            }
        }
        throw new TypeError(`Expected string but received ${invalidType}.`);
    }
}
function isInt(str, options) {
    const int = /^(?:[-+]?(?:0|[1-9][0-9]*))$/;
    const intLeadingZeroes = /^[-+]?[0-9]+$/;

    assertString(str);
    options = options || {};

    // Get the regex to use for testing, based on whether
    // leading zeroes are allowed or not.
    let regex =
        options.hasOwnProperty("allow_leading_zeroes") &&
        !options.allow_leading_zeroes
            ? int
            : intLeadingZeroes;

    // Check min/max/lt/gt
    let minCheckPassed = !options.hasOwnProperty("min") || str >= options.min;
    let maxCheckPassed = !options.hasOwnProperty("max") || str <= options.max;
    let ltCheckPassed = !options.hasOwnProperty("lt") || str < options.lt;
    let gtCheckPassed = !options.hasOwnProperty("gt") || str > options.gt;

    return (
        regex.test(str) &&
        minCheckPassed &&
        maxCheckPassed &&
        ltCheckPassed &&
        gtCheckPassed
    );
}

export function getBusiness(businessKey) {
    const url = isInt(businessKey)
        ? `${END_POINT}/businesses/search?dot=${businessKey}`
        : `${END_POINT}/businesses/public/${businessKey}`;
    return axios({
        url,
        timeout: 20000,
        method: "get"
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error;
        });
}

export function getStatus(loadNo, businessid) {
    return axios({
        url: `${END_POINT}/loads/track/${businessid}`,
        timeout: 20000,
        method: "get",
        params: {
            loadNo
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error;
        });
}
